import React, { createContext , useContext, useReducer, useEffect } from 'react';
import { fetchAllData } from './DataService';
import { firebaseAuth, googleProvider, signInWithPopup } from '../firebase/firebaseConfig';
import { onAuthStateChanged, signOut } from "firebase/auth";

const DataContext = createContext();

const dataReducer = (state, action) => {

    switch (action.type) {

        case 'SET_ALL_DATA':
            return {...state, allData: action.payload};
        case 'SET_APP_USER':
            return {...state, appUser: action.payload};
        case 'SET_APP_USER_SETTINGS':
            return {...state, appUserSettings: action.payload};
        case 'SET_SELECTED_CONTENT':
            return {...state, selectedContent: action.payload};
        case 'SET_PLAYLIST_CONTENT':
            return {...state, playlistContent: action.payload};     
        case 'SET_USER_PLAYLISTS':
            return {...state, userPlaylists: action.payload};                            
        case 'SET_USER_FAVORITES':
            return {...state, userFavorites: action.payload};     
        default:
            return state;

    }
};

const DataProvider = ({children}) => {

    const [state,dispatch] = useReducer(dataReducer, {data:null, user: null});

    useEffect(() => {

        const fetchAllDataAndUpdateState = async () => {

                const allData = await fetchAllData()
                .then(result => {dispatch({ type: 'SET_ALL_DATA', payload: result});})
                .catch(error => {throw new Error('Could not fetch content data',error);});
                
        };
        
        fetchAllDataAndUpdateState();
        
        const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
            if (user) {
                dispatch({ type: 'SET_APP_USER', payload: user });
            } else {
                dispatch({ type: 'SET_APP_USER', payload: null });
            }
        });

        return () => unsubscribe();
      

    }, []);

    const loginWithGoogle = async () => {
        try {
          const result = await signInWithPopup(firebaseAuth, googleProvider);
          return result.user;
        } catch (error) {
          console.error("Google Sign-In Error: ", error);
          throw error;
        }
      };

    const logout = async () => {
        try {
            await signOut(firebaseAuth);
        } catch (error) {
            console.error("Sign-Out Error: ", error);
        }
    };

    return (
        <DataContext.Provider value={{state,dispatch,loginWithGoogle,logout}}>
            {children}
        </DataContext.Provider>
    );
};

const useData = () => {
    const context = useContext(DataContext);
    if (!context){
        throw new Error('useData must be used within a Data Provider');
    }
    return context;
}

const updateAppUser = (dispatch, appUser) => {
    dispatch({ type: 'SET_APP_USER', payload: appUser });
  };

const updateAppUserSettings = (dispatch, appUserSettings) => {
    dispatch({ type: 'SET_APP_USER_SETTINGS', payload: appUserSettings });
};

const updateSelectedContent = (dispatch, selectedContent) => {
    dispatch({ type: 'SET_SELECTED_CONTENT', payload: selectedContent });
  };

const updatePlaylistContent = (dispatch, playlistContent) => {
    dispatch({ type: 'SET_PLAYLIST_CONTENT', payload: playlistContent });
  };  

const updateUserPlaylists = (dispatch, userPlaylists) => {
    dispatch({ type: 'SET_USER_PLAYLISTS', payload: userPlaylists });
  }; 

const updateUserFavorites = (dispatch, userFavorites) => {
    dispatch({ type: 'SET_USER_FAVORITES', payload: userFavorites });
 }; 

export {DataProvider,
        useData,
        updateSelectedContent,
        updatePlaylistContent,
        updateAppUser,
        updateAppUserSettings,
        updateUserPlaylists,
        updateUserFavorites
    };
