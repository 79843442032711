import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
  };
  
// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

const firebaseAuth = getAuth(firebaseApp);

const googleProvider = new GoogleAuthProvider();

const firebaseFirestore = getFirestore(firebaseApp);

//FireStore Collections
const firestoreCollectionsNames = {
  Users : "Users",
  User_Settings: 'User_Settings',
  User_Favorites : "User_Favorites",
  User_Playlists : "User_Playlists",
  User_Playlist_Items : "User_Playlist_Items",
  User_Favorites : "User_Favorites",
  User_Google_Account_Profile : "User_Google_Account_Profile"
} 

const firestoreMapSourceToCollection = {
  Users : { collection:"Users" },
  User_Settings: { collection:"User_Settings"},
  User_Favorites : { collection:"User_Favorites"},
  User_Playlists : { collection:"User_Playlists"},
  User_Playlist_Items : { collection: "User_Playlist_Items"},
  User_Favorites : { collection: "User_Favorites"},
  User_Google_Account_Profile : { collection: "User_Google_Account_Profile"}

} 

export {firebaseApp, firebaseAuth, firebaseFirestore, firestoreCollectionsNames, googleProvider, signInWithPopup };


  